<template>
  <!-- <div class="body-color"> -->
  <v-container class="body-register">
    <notification-popup
      :dialog="notifSuccess"
      :contentMsg="$t('succesfully')"
      :headerMsg="$t('succesfully')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelOk')"
      colorIcon="success"
      typeModal="transaction"
      Icon="mdi-check-circle"
      disableBtnNo="true"
      :onHandlerYes="buttonOke"
    />

    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('failed')"
      :headerMsg="$t('failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      colorIcon="danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      disableBtnNo="true"
      :onHandlerYes="buttonOkeFailed"
    />

    <div v-if="showBasicInformation == true">
      <v-form v-model="valid">
        <v-row>
          <v-col cols="12" class="pa-0">
            <p class="title-login mt-0 mb-2">{{ $t("Welcome") }}</p>
            <h2 class="h3-title">{{ $t("UpdateyourAcasiaAccount") }}</h2>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6" class="py-0 pl-0">
            <label class="label-content">{{ $t("first_name") }}</label>
            <v-text-field
              v-model="first_name"
              :placeholder="$t('first_name')"
              :rules="[rules.required, rules.minThree]"
              type="text"
              aria-autocomplete="false"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0 pr-0">
            <label class="label-content">{{ $t("last_name") }}</label>
            <v-text-field
              v-model="last_name"
              :placeholder="$t('last_name')"
              :rules="[rules.required, rules.minThree]"
              aria-autocomplete="false"
              type="text"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" class="py-0 px-0 mt-n3">
            <label class="label-content">{{ $t("username") }}</label>
            <v-text-field
              v-model="user_name"
              type="text"
              hidden
              disabled
              @keydown.space="(event) => event.preventDefault()"
              :rules="[rules.required, rules.spaces]"
              :placeholder="$t('username')"
              aria-autocomplete="false"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" class="py-0 px-0 mt-n3">
            <label class="label-content">{{ $t("email") }}</label>
            <v-text-field
              v-model="email"
              type="email"
              :placeholder="$t('email')"
              :rules="[rules.required]"
              required
              outlined
              disabled
              dense
              :append-icon="isEmailValid()"
              :error="isDangerEmail()"
              :success="isSuccessEmail()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12 pa-0">
            <label class="label-content font-weight-bold">{{
              $t("MobileNumber")
            }}</label>
          </v-col>
          <v-col cols="4" class="py-0 px-0">
            <v-autocomplete
              :items="cidcountry"
              item-text="name"
              item-value="dial_code"
              class="code_cid"
              required
              height="30"
              v-model="phone_cid"
              return-object
              outlined
              dense
              :rules="[() => !!phone_cid || 'This field is required']"
            ></v-autocomplete>
          </v-col>
          <v-col cols="8" class="py-0 pr-0">
            <label class="label-content"></label>
            <v-text-field
              :prefix="phone_cid ? phone_cid.dial_code : ''"
              label="Phone Number"
              type="number"
              v-model="phone_number"
              :rules="[rules.requiredPhone, rules.minPhone, rules.maxPhone]"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 mt-n3">
            <label class="label-content">{{ $t("DateOfBirth") }}</label>
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mt-0 pt-0"
                    :rules="[rules.required]"
                    v-model="dateFormatted"
                    placeholder="dd-mm-yyyy"
                    persistent-hint
                    readonly
                    required
                    outlined
                    dense
                    v-bind="attrs"
                    @blur="birth_of_date = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                  <!-- <v-text-field class="mt-0 pt-0"
                    v-model="format_date_birth"
                    placeholder="dd-mm-yyyy"
                    persistent-hint
                    required
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  @blur="birth_of_date = parseDate(format_date_birth)"
                  ></v-text-field> -->
                  
                </template>
                <v-date-picker
                  v-model="birth_of_date"
                  no-title
                  :max="now()"
                  min="1900-01-01"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 mt-n3">
            <label class="label-content">{{ $t("gender") }}</label>
            <v-select
              :rules="[rules.required]"
              :placeholder="$t('gender')"
              v-model="gender_"
              v-bind:items="genderItem"
              item-text="name"
              item-value="code"
              required
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" class="pa-0 mt-n3">
          <label class="label-content">{{ $t("Address") }}</label>
          <v-textarea
            v-model="address"
            :placeholder="$t('Address')"
          outlined
        ></v-textarea>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" class="pa-0 mt-n3">
          <label class="label-content">{{ $t("Occupation") }}</label>
          <v-text-field
            v-model="occupation"
            type="text"
            :placeholder="$t('Occupation')"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

        <v-row>
          <v-col cols="12" class="pa-0 mt-n3">
            <v-checkbox v-model="isTermsConditionAccepted" class="mt-0">
              <template v-slot:label>
                <div>{{ $t("registerCheck") }}</div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <!-- </v-card-text> -->

        <v-row>
          <v-col cols="12" align="center">
            <!-- <v-btn
              :class="buttonRegister ? 'btn-signup' : 'btn-signup-disabled'"
              rounded
              outlined
              color="white"
              elevation="0"
              :disabled="buttonRegister == false && valid == false"
              @click="clickNext()"
              >{{ $t("Next") }}</v-btn
            > -->
            <v-btn
              class="mx-2 btn-submit text-capitalize white--text"
              :disabled="validateForm == true"
              rounded
              :color="$partnerACL.getButtonColor()"
              elevation="0"
              @click="registerUser()"
              ><span>{{ $t("Submit") }}</span></v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>

    <!-- <div v-if="showAddPayment == true">
      <v-row>
        <v-col>
          <h4 class="mb-0 h3-title">{{ $t("PaymentInformation") }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <label class="label-content">{{ $t("selectPaymentMethod") }}</label>
          <v-select
            :placeholder="$t('selectPaymentMethod')"
            append-icon="mdi-chevron-down"
            v-bind:items="items"
            v-model="payment_source"
            item-text="wallet_name"
            item-value="wallet_code"
            type="text"
            outlined
            dense
          >
            <template slot="item" slot-scope="data">
              <v-img
                maxHeight="28"
                maxWidth="28"
                :src="data.item.wallet_img"
              ></v-img>
              {{ data.item.wallet_name }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12 pa-0 mt-n3">
          <label class="label-content mb-1">{{ $t("paymentID") }}</label>
          <v-text-field
            v-model="payment_id"
            :placeholder="$t('paymentID')"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="pa-0 mt-n3">
          <v-checkbox v-model="paymentConditionAccepted">
            <template v-slot:label>
              <div>{{ $t("registerCheckPayment") }}</div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" align="center">
          <AcButton
            class="mx-2 btn-back primary--text"
            color="primary"
            :name="$t('Back')"
            width="50%"
            rounded
            :onClickHandler="clickBack"
          />
        </v-col>
        <v-col cols="6" align="center">
          <v-btn
            class="mx-2 btn-submit"
            :disabled="buttonRegisterFinish == false"
            rounded
            color="primary"
            elevation="0"
            @click="registerUser()"
            ><span>{{ $t("Submit") }}</span></v-btn
          >
        </v-col>
      </v-row>
    </div> -->
  </v-container>
  <!-- </div> -->
</template>
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
import moment from "moment";
import allCountries from "@/components/Countries";


export default {
  name: "RegisterUpdate",
  components: { 
    // AcButton,
  NotificationPopup },
  data() {
    return {
      dateFormatted: '',
      menu1: false,
      valid: false,
      address:"",
      occupation:"",
      cidcountry: [
        { name: "Israel", dial_code: "+972", code: "IL" },
        { name: "Afghanistan", dial_code: "+93", code: "AF" },
        { name: "Albania", dial_code: "+355", code: "AL" },
        { name: "Algeria", dial_code: "+213", code: "DZ" },
        { name: "AmericanSamoa", dial_code: "+1 684", code: "AS" },
        { name: "Andorra", dial_code: "+376", code: "AD" },
        { name: "Angola", dial_code: "+244", code: "AO" },
        { name: "Anguilla", dial_code: "+1 264", code: "AI" },
        { name: "Antigua and Barbuda", dial_code: "+1268", code: "AG" },
        { name: "Argentina", dial_code: "+54", code: "AR" },
        { name: "Armenia", dial_code: "+374", code: "AM" },
        { name: "Aruba", dial_code: "+297", code: "AW" },
        { name: "Australia", dial_code: "+61", code: "AU" },
        { name: "Austria", dial_code: "+43", code: "AT" },
        { name: "Azerbaijan", dial_code: "+994", code: "AZ" },
        { name: "Bahamas", dial_code: "+1 242", code: "BS" },
        { name: "Bahrain", dial_code: "+973", code: "BH" },
        { name: "Bangladesh", dial_code: "+880", code: "BD" },
        { name: "Barbados", dial_code: "+1 246", code: "BB" },
        { name: "Belarus", dial_code: "+375", code: "BY" },
        { name: "Belgium", dial_code: "+32", code: "BE" },
        { name: "Belize", dial_code: "+501", code: "BZ" },
        { name: "Benin", dial_code: "+229", code: "BJ" },
        { name: "Bermuda", dial_code: "+1 441", code: "BM" },
        { name: "Bhutan", dial_code: "+975", code: "BT" },
        { name: "Bosnia and Herzegovina", dial_code: "+387", code: "BA" },
        { name: "Botswana", dial_code: "+267", code: "BW" },
        { name: "Brazil", dial_code: "+55", code: "BR" },
        {
          name: "British Indian Ocean Territory",
          dial_code: "+246",
          code: "IO",
        },
        { name: "Bulgaria", dial_code: "+359", code: "BG" },
        { name: "Burkina Faso", dial_code: "+226", code: "BF" },
        { name: "Burundi", dial_code: "+257", code: "BI" },
        { name: "Cambodia", dial_code: "+855", code: "KH" },
        { name: "Cameroon", dial_code: "+237", code: "CM" },
        { name: "Canada", dial_code: "+1", code: "CA" },
        { name: "Cape Verde", dial_code: "+238", code: "CV" },
        { name: "Cayman Islands", dial_code: "+ 345", code: "KY" },
        { name: "Central African Republic", dial_code: "+236", code: "CF" },
        { name: "Chad", dial_code: "+235", code: "TD" },
        { name: "Chile", dial_code: "+56", code: "CL" },
        { name: "China", dial_code: "+86", code: "CN" },
        { name: "Christmas Island", dial_code: "+61", code: "CX" },
        { name: "Colombia", dial_code: "+57", code: "CO" },
        { name: "Comoros", dial_code: "+269", code: "KM" },
        { name: "Congo", dial_code: "+242", code: "CG" },
        { name: "Cook Islands", dial_code: "+682", code: "CK" },
        { name: "Costa Rica", dial_code: "+506", code: "CR" },
        { name: "Croatia", dial_code: "+385", code: "HR" },
        { name: "Cuba", dial_code: "+53", code: "CU" },
        { name: "Cyprus", dial_code: "+537", code: "CY" },
        { name: "Czech Republic", dial_code: "+420", code: "CZ" },
        { name: "Denmark", dial_code: "+45", code: "DK" },
        { name: "Djibouti", dial_code: "+253", code: "DJ" },
        { name: "Dominica", dial_code: "+1 767", code: "DM" },
        { name: "Dominican Republic", dial_code: "+1 849", code: "DO" },
        { name: "Ecuador", dial_code: "+593", code: "EC" },
        { name: "Egypt", dial_code: "+20", code: "EG" },
        { name: "El Salvador", dial_code: "+503", code: "SV" },
        { name: "Equatorial Guinea", dial_code: "+240", code: "GQ" },
        { name: "Eritrea", dial_code: "+291", code: "ER" },
        { name: "Estonia", dial_code: "+372", code: "EE" },
        { name: "Ethiopia", dial_code: "+251", code: "ET" },
        { name: "Faroe Islands", dial_code: "+298", code: "FO" },
        { name: "Fiji", dial_code: "+679", code: "FJ" },
        { name: "Finland", dial_code: "+358", code: "FI" },
        { name: "France", dial_code: "+33", code: "FR" },
        { name: "French Guiana", dial_code: "+594", code: "GF" },
        { name: "French Polynesia", dial_code: "+689", code: "PF" },
        { name: "Gabon", dial_code: "+241", code: "GA" },
        { name: "Gambia", dial_code: "+220", code: "GM" },
        { name: "Georgia", dial_code: "+995", code: "GE" },
        { name: "Germany", dial_code: "+49", code: "DE" },
        { name: "Ghana", dial_code: "+233", code: "GH" },
        { name: "Gibraltar", dial_code: "+350", code: "GI" },
        { name: "Greece", dial_code: "+30", code: "GR" },
        { name: "Greenland", dial_code: "+299", code: "GL" },
        { name: "Grenada", dial_code: "+1 473", code: "GD" },
        { name: "Guadeloupe", dial_code: "+590", code: "GP" },
        { name: "Guam", dial_code: "+1 671", code: "GU" },
        { name: "Guatemala", dial_code: "+502", code: "GT" },
        { name: "Guinea", dial_code: "+224", code: "GN" },
        { name: "Guinea-Bissau", dial_code: "+245", code: "GW" },
        { name: "Guyana", dial_code: "+595", code: "GY" },
        { name: "Haiti", dial_code: "+509", code: "HT" },
        { name: "Honduras", dial_code: "+504", code: "HN" },
        { name: "Hungary", dial_code: "+36", code: "HU" },
        { name: "Iceland", dial_code: "+354", code: "IS" },
        { name: "India", dial_code: "+91", code: "IN" },
        { name: "Indonesia", dial_code: "+62", code: "ID" },
        { name: "Iraq", dial_code: "+964", code: "IQ" },
        { name: "Ireland", dial_code: "+353", code: "IE" },
        { name: "Israel", dial_code: "+972", code: "IL" },
        { name: "Italy", dial_code: "+39", code: "IT" },
        { name: "Jamaica", dial_code: "+1 876", code: "JM" },
        { name: "Japan", dial_code: "+81", code: "JP" },
        { name: "Jordan", dial_code: "+962", code: "JO" },
        { name: "Kazakhstan", dial_code: "+7 7", code: "KZ" },
        { name: "Kenya", dial_code: "+254", code: "KE" },
        { name: "Kiribati", dial_code: "+686", code: "KI" },
        { name: "Kuwait", dial_code: "+965", code: "KW" },
        { name: "Kyrgyzstan", dial_code: "+996", code: "KG" },
        { name: "Latvia", dial_code: "+371", code: "LV" },
        { name: "Lebanon", dial_code: "+961", code: "LB" },
        { name: "Lesotho", dial_code: "+266", code: "LS" },
        { name: "Liberia", dial_code: "+231", code: "LR" },
        { name: "Liechtenstein", dial_code: "+423", code: "LI" },
        { name: "Lithuania", dial_code: "+370", code: "LT" },
        { name: "Luxembourg", dial_code: "+352", code: "LU" },
        { name: "Madagascar", dial_code: "+261", code: "MG" },
        { name: "Malawi", dial_code: "+265", code: "MW" },
        { name: "Malaysia", dial_code: "+60", code: "MY" },
        { name: "Maldives", dial_code: "+960", code: "MV" },
        { name: "Mali", dial_code: "+223", code: "ML" },
        { name: "Malta", dial_code: "+356", code: "MT" },
        { name: "Marshall Islands", dial_code: "+692", code: "MH" },
        { name: "Martinique", dial_code: "+596", code: "MQ" },
        { name: "Mauritania", dial_code: "+222", code: "MR" },
        { name: "Mauritius", dial_code: "+230", code: "MU" },
        { name: "Mayotte", dial_code: "+262", code: "YT" },
        { name: "Mexico", dial_code: "+52", code: "MX" },
        { name: "Monaco", dial_code: "+377", code: "MC" },
        { name: "Mongolia", dial_code: "+976", code: "MN" },
        { name: "Montenegro", dial_code: "+382", code: "ME" },
        { name: "Montserrat", dial_code: "+1664", code: "MS" },
        { name: "Morocco", dial_code: "+212", code: "MA" },
        { name: "Myanmar", dial_code: "+95", code: "MM" },
        { name: "Namibia", dial_code: "+264", code: "NA" },
        { name: "Nauru", dial_code: "+674", code: "NR" },
        { name: "Nepal", dial_code: "+977", code: "NP" },
        { name: "Netherlands", dial_code: "+31", code: "NL" },
        { name: "Netherlands Antilles", dial_code: "+599", code: "AN" },
        { name: "New Caledonia", dial_code: "+687", code: "NC" },
        { name: "New Zealand", dial_code: "+64", code: "NZ" },
        { name: "Nicaragua", dial_code: "+505", code: "NI" },
        { name: "Niger", dial_code: "+227", code: "NE" },
        { name: "Nigeria", dial_code: "+234", code: "NG" },
        { name: "Niue", dial_code: "+683", code: "NU" },
        { name: "Norfolk Island", dial_code: "+672", code: "NF" },
        { name: "Northern Mariana Islands", dial_code: "+1 670", code: "MP" },
        { name: "Norway", dial_code: "+47", code: "NO" },
        { name: "Oman", dial_code: "+968", code: "OM" },
        { name: "Pakistan", dial_code: "+92", code: "PK" },
        { name: "Palau", dial_code: "+680", code: "PW" },
        { name: "Panama", dial_code: "+507", code: "PA" },
        { name: "Papua New Guinea", dial_code: "+675", code: "PG" },
        { name: "Paraguay", dial_code: "+595", code: "PY" },
        { name: "Peru", dial_code: "+51", code: "PE" },
        { name: "Philippines", dial_code: "+63", code: "PH" },
        { name: "Poland", dial_code: "+48", code: "PL" },
        { name: "Portugal", dial_code: "+351", code: "PT" },
        { name: "Puerto Rico", dial_code: "+1 939", code: "PR" },
        { name: "Qatar", dial_code: "+974", code: "QA" },
        { name: "Romania", dial_code: "+40", code: "RO" },
        { name: "Rwanda", dial_code: "+250", code: "RW" },
        { name: "Samoa", dial_code: "+685", code: "WS" },
        { name: "San Marino", dial_code: "+378", code: "SM" },
        { name: "Saudi Arabia", dial_code: "+966", code: "SA" },
        { name: "Senegal", dial_code: "+221", code: "SN" },
        { name: "Serbia", dial_code: "+381", code: "RS" },
        { name: "Seychelles", dial_code: "+248", code: "SC" },
        { name: "Sierra Leone", dial_code: "+232", code: "SL" },
        { name: "Singapore", dial_code: "+65", code: "SG" },
        { name: "Slovakia", dial_code: "+421", code: "SK" },
        { name: "Slovenia", dial_code: "+386", code: "SI" },
        { name: "Solomon Islands", dial_code: "+677", code: "SB" },
        { name: "South Africa", dial_code: "+27", code: "ZA" },
        {
          name: "South Georgia and the South Sandwich Islands",
          dial_code: "+500",
          code: "GS",
        },
        { name: "Spain", dial_code: "+34", code: "ES" },
        { name: "Sri Lanka", dial_code: "+94", code: "LK" },
        { name: "Sudan", dial_code: "+249", code: "SD" },
        { name: "Suriname", dial_code: "+597", code: "SR" },
        { name: "Swaziland", dial_code: "+268", code: "SZ" },
        { name: "Sweden", dial_code: "+46", code: "SE" },
        { name: "Switzerland", dial_code: "+41", code: "CH" },
        { name: "Tajikistan", dial_code: "+992", code: "TJ" },
        { name: "Thailand", dial_code: "+66", code: "TH" },
        { name: "Togo", dial_code: "+228", code: "TG" },
        { name: "Tokelau", dial_code: "+690", code: "TK" },
        { name: "Tonga", dial_code: "+676", code: "TO" },
        { name: "Trinidad and Tobago", dial_code: "+1 868", code: "TT" },
        { name: "Tunisia", dial_code: "+216", code: "TN" },
        { name: "Turkey", dial_code: "+90", code: "TR" },
        { name: "Turkmenistan", dial_code: "+993", code: "TM" },
        { name: "Turks and Caicos Islands", dial_code: "+1 649", code: "TC" },
        { name: "Tuvalu", dial_code: "+688", code: "TV" },
        { name: "Uganda", dial_code: "+256", code: "UG" },
        { name: "Ukraine", dial_code: "+380", code: "UA" },
        { name: "United Arab Emirates", dial_code: "+971", code: "AE" },
        { name: "United Kingdom", dial_code: "+44", code: "GB" },
        { name: "United States", dial_code: "+1", code: "US" },
        { name: "Uruguay", dial_code: "+598", code: "UY" },
        { name: "Uzbekistan", dial_code: "+998", code: "UZ" },
        { name: "Vanuatu", dial_code: "+678", code: "VU" },
        { name: "Wallis and Futuna", dial_code: "+681", code: "WF" },
        { name: "Yemen", dial_code: "+967", code: "YE" },
        { name: "Zambia", dial_code: "+260", code: "ZM" },
        { name: "Zimbabwe", dial_code: "+263", code: "ZW" },
        { name: "land Islands", dial_code: "", code: "AX" },
        { name: "Antarctica", dial_code: null, code: "AQ" },
        {
          name: "Bolivia, Plurinational State of",
          dial_code: "+591",
          code: "BO",
        },
        { name: "Brunei Darussalam", dial_code: "+673", code: "BN" },
        { name: "Cocos (Keeling) Islands", dial_code: "+61", code: "CC" },
        {
          name: "Congo, The Democratic Republic of the",
          dial_code: "+243",
          code: "CD",
        },
        { name: "Cote d'Ivoire", dial_code: "+225", code: "CI" },
        { name: "Falkland Islands (Malvinas)", dial_code: "+500", code: "FK" },
        { name: "Guernsey", dial_code: "+44", code: "GG" },
        {
          name: "Holy See (Vatican City State)",
          dial_code: "+379",
          code: "VA",
        },
        { name: "Hong Kong", dial_code: "+852", code: "HK" },
        { name: "Iran, Islamic Republic of", dial_code: "+98", code: "IR" },
        { name: "Isle of Man", dial_code: "+44", code: "IM" },
        { name: "Jersey", dial_code: "+44", code: "JE" },
        {
          name: "Korea, Democratic People's Republic of",
          dial_code: "+850",
          code: "KP",
        },
        { name: "Korea, Republic of", dial_code: "+82", code: "KR" },
        {
          name: "Lao People's Democratic Republic",
          dial_code: "+856",
          code: "LA",
        },
        { name: "Libyan Arab Jamahiriya", dial_code: "+218", code: "LY" },
        { name: "Macao", dial_code: "+853", code: "MO" },
        {
          name: "Macedonia, The Former Yugoslav Republic of",
          dial_code: "+389",
          code: "MK",
        },
        {
          name: "Micronesia, Federated States of",
          dial_code: "+691",
          code: "FM",
        },
        { name: "Moldova, Republic of", dial_code: "+373", code: "MD" },
        { name: "Mozambique", dial_code: "+258", code: "MZ" },
        {
          name: "Palestinian Territory, Occupied",
          dial_code: "+970",
          code: "PS",
        },
        { name: "Pitcairn", dial_code: "+872", code: "PN" },
        { name: "Réunion", dial_code: "+262", code: "RE" },
        { name: "Russia", dial_code: "+7", code: "RU" },
        { name: "Saint Barthélemy", dial_code: "+590", code: "BL" },
        {
          name: "Saint Helena, Ascension and Tristan Da Cunha",
          dial_code: "+290",
          code: "SH",
        },
        { name: "Saint Kitts and Nevis", dial_code: "+1 869", code: "KN" },
        { name: "Saint Lucia", dial_code: "+1 758", code: "LC" },
        { name: "Saint Martin", dial_code: "+590", code: "MF" },
        { name: "Saint Pierre and Miquelon", dial_code: "+508", code: "PM" },
        {
          name: "Saint Vincent and the Grenadines",
          dial_code: "+1 784",
          code: "VC",
        },
        { name: "Sao Tome and Principe", dial_code: "+239", code: "ST" },
        { name: "Somalia", dial_code: "+252", code: "SO" },
        { name: "Svalbard and Jan Mayen", dial_code: "+47", code: "SJ" },
        { name: "Syrian Arab Republic", dial_code: "+963", code: "SY" },
        { name: "Taiwan, Province of China", dial_code: "+886", code: "TW" },
        { name: "Tanzania, United Republic of", dial_code: "+255", code: "TZ" },
        { name: "Timor-Leste", dial_code: "+670", code: "TL" },
        {
          name: "Venezuela, Bolivarian Republic of",
          dial_code: "+58",
          code: "VE",
        },
        { name: "Vietnam", dial_code: "+84", code: "VN" },
        { name: "Virgin Islands, British", dial_code: "+1 284", code: "VG" },
        { name: "Virgin Islands, U.S.", dial_code: "+1 340", code: "VI" },
      ],
      showBasicInformation: true,
      showAddPayment: false,
      notifSuccess: false,
      notifFailed: false,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      user_name: "",
      password: "",
      re_type_password: "",
      birth_of_date: "",
      gender_: "",
      paymentConditionAccepted: false,
      isTermsConditionAccepted: false,
      show: false,
      show_re_type_password: false,
      genderItem: [
        {
          code: "f",
          name: this.$t("Female"),
        },
        {
          code: "m",
          name: this.$t("Male"),
        },
      ],
      rules: {
        required: (value) => !(/^\s*$/.test(value)) || this.$t("Required"),
        min: (v) => v.length >= 6 || this.$t("MinSixCharacters"),
        // minThree: (v) => v.length >= 3 || this.$t("MinThreeCharacters"),
        emailMatch: () => this.$t("TheEmailPasswordEnteredDontMatch"),
        requiredPhone: (value) => (/^[0-9]+$/.test(value)) || this.$t("RequiredSpesificNumber"),
        minPhone: (value) => value.length >= 7 || this.$t("MinSevenCharacters"),
        maxPhone: (value) => value.length <= 15 || this.$t("MaxFifteenCharacters"),
        matchPhoneNumber: () => /^(([0-9]))$/,
        spaces: (v) =>
          (v || "").indexOf(" ") < 0 || this.$t("NoSpacesAreAllowed"),
        confirmPasswordRules: (v) =>
          v === this.password || this.$t("ThePasswordConfirmationDoesNotMatch"),
      },
      items: [
        {
          wallet_name: "MOCA",
          wallet_code: "01",
          wallet_img: require("@/assets/moca.png"),
        },
        {
          wallet_name: "ZALOPAY",
          wallet_code: "02",
          wallet_img: require("@/assets/zalopay.png"),
        },
        {
          wallet_name: "MOMO",
          wallet_code: "03",
          wallet_img: require("@/assets/momo.png"),
        },
      ],
      payment: "",
      buttonRegister: false,
      buttonRegisterFinish: false,
      payment_id: "",
      payment_source: "",
      phone_cid: "",
      countries: allCountries,
      format_date_birth:"",
    };
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.birth_of_date)
    },
    validateForm() {
      var data=false
      if(!this.valid || this.first_name == "" || this.last_name == "" || this.email == "" ||
        this.birth_of_date == "" || this.phone_number == "" || this.gender_ == ""  || this.isTermsConditionAccepted == false) {
          data=true
        }
      return data
    }
  },
  watch: {
    birth_of_date () {
      this.dateFormatted = this.formatDate(this.birth_of_date)
    },
    valid() {
      console.log("aaa ",this.valid);
    },
    cancelFailed() {
      this.notifFailed = false;
    },
    cancelSuccess() {
      this.notifSuccess = false;
    },
    payment_source() {
      if (
        this.paymentConditionAccepted == false &&
        this.payment_id != "" &&
        this.payment_source != ""
      ) {
        this.buttonRegisterFinish = true;
      } else if (this.paymentConditionAccepted == true) {
        this.buttonRegisterFinish = true;
      } else {
        this.buttonRegisterFinish = false;
      }
    },
    payment_id() {
      if (
        this.paymentConditionAccepted == false &&
        this.payment_id != "" &&
        this.payment_source != ""
      ) {
        this.buttonRegisterFinish = true;
      } else if (this.paymentConditionAccepted == true) {
        this.buttonRegisterFinish = true;
      } else {
        this.buttonRegisterFinish = false;
      }
    },
    paymentConditionAccepted() {
      if (this.paymentConditionAccepted == true) {
        this.payment_source = "";
        this.payment_id = "";
        this.buttonRegisterFinish = true;
      } else {
        this.buttonRegisterFinish = false;
      }
    },

    isTermsConditionAccepted() {
      console.log( this.isTermsConditionAccepted)
      console.log( "validagtefrom",this.validateForm)
      
    },
    validateForm(){
      console.log(this.validateForm)
      if(this.validateForm == true){
        this.isTermsConditionAccepted = false
      }
    }
  },
  methods: {
    now() {
      return moment().format('YYYY-MM-DD')
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clickBack() {
      this.showBasicInformation = true;
      this.showAddPayment = false;
    },
    clickNext() {
      if (this.valid) {
        this.showBasicInformation = false;
        this.showAddPayment = true;
      } else {
        this.notifFailed = true;
      }
    },
    acceptNumber(item) {
      var x = item.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      item = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      // console.log("accept number", item);
    },
    isNumber() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      // console.log("as", re.test(this.email))
      let data = "";
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = "mdi-check";
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = "mdi-alert-circle-outline";
      }
      return data;
    },
    isEmailValid() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      // console.log("as", re.test(this.email))
      let data = "";
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = "mdi-check";
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = "mdi-alert-circle-outline";
      }
      return data;
    },

    isSuccessEmail() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      let data = false;
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = false;
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = true;
      }

      return data;
    },
    isDangerEmail() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      let data = false;
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = false;
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = true;
      }

      return data;
    },
    registerUser() {
      if (!this.validateForm) {
        this.notifFailed = false;
      }

      if (this.paymentConditionAccepted == true || this.payment_source == "") {
        this.payment = "";
        this.payment_id = "";
      } else {
        this.payment = this.payment_source;
      }

      let caller_id;
      if (this.phone_cid.dial_code == undefined) {
        caller_id = this.phone_cid.dial_code;
      } else {
        caller_id = this.phone_cid.dial_code;
      }
      console.log("birth of date",this.birth_of_date);
      // const payload = {
      //   psg_first_name: this.first_name,
      //   psg_last_name: this.last_name,
      //   psg_phone_number: this.phone_number,
      //   cid: caller_id,
      //   user_name: this.user_name,
      //   psg_birthday: this.birth_of_date,
      //   user_email: this.email,
      //   password: this.password,
      //   re_type_password: this.re_type_password,
      //   payment_source: this.payment,
      //   payment_id: this.payment_id,
      //   psg_gender: this.gender_,
      // };
      // console.log("this.phone_cid ", this.phone_cid)
      const payload = {
        psg_first_name: this.first_name,
        psg_last_name: this.last_name,
        user_email: this.email,
        psg_phone_cid: caller_id,
        psg_phone_number: this.phone_number,
        psg_birthday: this.birth_of_date,
        psg_gender: this.gender_,
        address: this.address,
        occupation: this.occupation,
        // payment_source: this.payment,
        // payment_id: this.payment_id,
      };
      console.log("response ", payload);

      this.$store
        .dispatch("passenger/registerEditProfilGobus", payload)
        .then((response) => {
          if (response.status == true) {
            this.notifSuccess = true;
          } else {
            this.notifFailed = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickLogin() {
      this.$router.push({ name: "Login" });
    },

    buttonOke() {
      this.notifSuccess = false;
      if (this.notifSuccess == false) this.$router.push({ name: "Login" });
    },
    buttonOkeFailed() {
      this.notifFailed = false;
      // if (this.notifFailed == false) this.$router.push({ name: "PaymentMethodAdd" });
    },
  },
  mounted() {
    // console.log(this.$store.state.passenger.passengerData.data);
    // for (let data of cidcountry) {
    //   if (data.code == "VN") {
    //     this.phone_cid = data;
    //   }
    // }
    const dataPassenger = this.$store.state.passenger.passengerData.data;
    this.first_name = dataPassenger.psg_first_name;
    this.last_name = dataPassenger.psg_last_name;
    this.phone_number = dataPassenger.psg_phone_number;
    this.user_name = dataPassenger.user_name;
    this.phone_cid = { name: "Vietnam", dial_code: "+84", code: "VN" };
    this.birth_of_date = "";
    this.dateFormatted = "";
    this.email = dataPassenger.user_email;
    this.payment = dataPassenger.user_email;
    console.log(this.$route);
  },
};
</script>

<style scoped>
.btn-signup {
  background-color: #4bb14e;
  color: white;
  text-transform: capitalize;
}
.btn-signup-disabled {
  background-color: #f5f5f5;
  color: grey;
  text-transform: capitalize;
}
.btn-login {
  color: #4bb14e;
  background-color: #f5f5f5;
  text-transform: capitalize;
}
.btn-back {
  background-color: #f5f5f5;
  text-transform: capitalize;
}
/* .btn-submit {
  color: #4bb14e;
  background-color: #f5f5f5;
  text-transform: capitalize;
} */
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .body-color {
    background-color: #f5fdf7;
    height: 100%;
    width: 100%;
  }

  .body-register {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .h3-title {
    color: #4bb14e;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 1rem;
  }
  .title-login {
    color: #4bb14e;
    font-size: 16px;
    line-height: 19px;
    margin-top: 1rem;
  }
  .label-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .footer {
    /* padding-top: 140px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .countries-dropdown{
  height: 17px;
  width: auto;
  padding-right: 5px;
} */
  .tel-input {
    height: 48px;
  }
  .autocomplete-cid {
    background: #4bb14e;
    text-emphasis-color: #ffffff;
    height: 40px;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .body-color {
    background-color: #f5fdf7;
    height: 100%;
    width: 100%;
  }

  .body-register {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .h3-title {
    color: #4bb14e;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 1rem;
    margin-top: -10px;
  }
  .title-login {
    color: #4bb14e;
    font-size: 14px;
    line-height: 19px;
    margin-top: 1rem;
  }
  .label-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .footer {
    /* padding-top: 140px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .countries-dropdown {
    height: 17px;
    width: auto;
    padding-right: 5px;
  }
  .tel-input {
    height: 48px;
  }
  .autocomplete-cid {
    text-emphasis-color: #ffffff;
    height: 40px;
    background: #4bb14e;
  }
}
</style>
